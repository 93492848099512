import React from 'react';
import { slide as Menu } from 'react-burger-menu';
import '../SavedProfile/SavedProfile.scss';
import { Link } from 'react-router-dom';
import { FaUserCircle } from "react-icons/fa";
import { IoSettingsOutline } from "react-icons/io5";
import { LuWallet } from "react-icons/lu";
import { PiSuitcaseSimpleLight } from "react-icons/pi";
import { CiLocationOn } from "react-icons/ci";
import { HiOutlineAcademicCap } from "react-icons/hi2";
import { GoLinkExternal } from "react-icons/go";
import { IoMdBookmark } from "react-icons/io";
import Scrollbar from 'react-scrollbar';

const jobProfiles = [
  {
    id: 1,
    name: 'Edward',
    image: 'https://i.pravatar.cc/150?img=3',
    experience: '5yrs 6m',
    education: 'B.Sc. in Computer Science',
    skills: 'JavaScript, React, Node.js',
    location: 'New York, USA',
    salary: '$8,6440',
    date: 'Today'
  },
  {
    id: 2,
    name: 'Jonathan',
    image: 'https://i.pravatar.cc/150?img=4',
    experience: '3yrs 2m',
    education: 'B.A. in Design',
    skills: 'Devops, Java, AWS, HTML5, Scss',
    location: 'Washington, USA',
    salary: '$6,8440',
    date: 'Yesterday'
  },

  {
    id: 3,
    name: 'Benjamin',
    image: 'https://i.pravatar.cc/150?img=8',
    experience: '8yrs 2m',
    education: 'BS in CS, IT, relevant engineering field',
    skills: 'Figma, Adobe XD, Wireframing',
    location: 'California, USA',
    salary: '$8,5940',
    date: 'Yesterday'
  },

  {
    id: 4,
    name: 'Douglas',
    image: 'https://i.pravatar.cc/150?img=5',
    experience: '7yrs 2m',
    education: 'B.S.C in Design',
    skills: 'Devops, Java, AWS, HTML5, Scss',
    location: 'Washington, USA',
    salary: '$5,2940',
    date: 'Yesterday'

  },

  {
    id: 5,
    name: 'Lawrence',
    image: 'https://i.pravatar.cc/150?img=4',
    experience: '3yrs 2m',
    education: 'BS in CS, IT, relevant engineering field',
    skills: 'Splunk, Angular, and .NET framework.',
    location: 'New York, USA',
    salary: '$9,1940',
    date: 'Yesterday'
  },

  {
    id: 6,
    name: 'Gabriel',
    image: 'https://i.pravatar.cc/150?img=12',
    experience: '8yrs 2m',
    education: 'BS in CS, IT, relevant engineering field',
    skills: 'Docker, Apache POI, Kubernetes',
    location: 'California, USA',
    salary: '$10,1940',
    date: 'Yesterday'
  },

  {
    id: 7,
    name: 'Vincent',
    image: 'https://i.pravatar.cc/150?img=5',
    experience: '7yrs 2m',
    education: 'B.S.C in Design',
    skills: 'Jenkins, Java, Azure App Service, Prometheus',
    location: 'New York, USA',
    salary: '$15,1940',
    date: 'Yesterday'

  },
  // Add more profiles as needed
];


const Messages = ({ isOpen, onClose }) => {
  const handleItemClick = () => {
    onClose(); // Close the menu when an item is clicked
  };

  return (
    <Menu right isOpen={isOpen} onClose={onClose}>
    <div className='d-flex justify-content- border-bottom py-4 px-3 align-items-center vertical-bottom'>
    <div className='p-3 pb-3 pt-4'>
          <h4>Search History</h4>
        </div>
        <div>
        </div>

      </div>
      <Scrollbar
        speed={0.8}
        className="custom-scrollbar"
        horizontal={false}
      >

        {jobProfiles.map(profile => (
          <p className='p-0 mx-4'>
          <div className="d-flex align-items-stretch mx-2 px-0 py-4 me-2 border-bottom" key={profile.id}>
            <div className='flex-grow-1'>
              <div className="me-3">
                <div className='d-flex justify-content-between'>
                  <div>
                  <label className='small m-0 p-0 mb-3'>{profile.date}</label>
                    <h5 className="card-title fs-5 mb-1">{profile.name}</h5>
                    <span className="card-text small">000{profile.id}</span>
                  </div>
                  <div>
                    <IoMdBookmark className='me-3 text-primary' />
                    <GoLinkExternal className='text-primary' />
                  </div>

                </div>
                  <div className='d-sm-flex'>
                    <div className="card-text mt-2 small me-3">
                      <LuWallet className="me-2" /> {profile.salary}
                    </div>
                    <div className="card-text mt-2 small me-3">
                      <PiSuitcaseSimpleLight className="me-2" /> {profile.experience}
                    </div>
                    <div className="card-text mt-2 small">
                      <CiLocationOn className="me-2" /> {profile.location}
                    </div>
                  </div>
                  <div className='d-flex'>
                    <div className="card-text mt-2 small">
                      <HiOutlineAcademicCap className="me-2" /> {profile.education}
                    </div>
                  </div>
                  <div className='d-flex mt-2'>
                    <div className="card-text small">
                      <IoSettingsOutline className="me-2" /> {profile.skills}
                    </div>
                  </div>
                </div>
              </div>
              <div>
              </div>

            </div>
          </p>
        ))}



      </Scrollbar>
    </Menu>
  );
};

export default Messages;
