// RelatedProfile.js
import React from 'react';
import { LuWallet } from "react-icons/lu";
import { PiSuitcaseSimpleLight } from "react-icons/pi";
import { CiLocationOn } from "react-icons/ci";
import { HiOutlineAcademicCap } from "react-icons/hi2";
import { IoSettingsOutline } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';

export const jobProfiles = [
  {
    id: 1,
    name: 'Edward',
    image: 'https://i.pravatar.cc/150?img=3',
    experience: '5yrs 6m',
    education: 'B.Sc. in Computer Science',
    skills: 'JavaScript, React, Node.js',
    location: 'New York, USA',
    salary: '$8,6440',
    date: 'Today'
  },
  {
    id: 2,
    name: 'Jonathan',
    image: 'https://i.pravatar.cc/150?img=4',
    experience: '3yrs 2m',
    education: 'B.A. in Design',
    skills: 'Devops, Java, AWS, HTML5, Scss',
    location: 'Washington, USA',
    salary: '$6,8440',
    date: 'Yesterday'
  },

  {
    id: 3,
    name: 'Benjamin',
    image: 'https://i.pravatar.cc/150?img=8',
    experience: '8yrs 2m',
    education: 'BS in CS, IT, relevant engineering field',
    skills: 'Figma, Adobe XD, Wireframing',
    location: 'California, USA',
    salary: '$8,5940',
    date: 'Yesterday'
  },

  {
    id: 4,
    name: 'Douglas',
    image: 'https://i.pravatar.cc/150?img=5',
    experience: '7yrs 2m',
    education: 'B.S.C in Design',
    skills: 'Devops, Java, AWS, HTML5, Scss',
    location: 'Washington, USA',
    salary: '$5,2940',
    date: 'Yesterday'

  },

  {
    id: 5,
    name: 'Lawrence',
    image: 'https://i.pravatar.cc/150?img=4',
    experience: '3yrs 2m',
    education: 'BS in CS, IT, relevant engineering field',
    skills: 'Splunk, Angular, and .NET framework.',
    location: 'New York, USA',
    salary: '$9,1940',
    date: 'Yesterday'
  },

  // Add more profiles as needed
];

function RelatedProfile() {
  const navigate = useNavigate();

  const handleProfileClick = (id) => {
    navigate(`/profile/${id}`);
  };

  return (
    <div className="container">
      <div className="row">
        {jobProfiles.map(profile => (
          <div
            className='col-12 card rounded-4 p-2 mb-4'
            key={profile.id}
            onClick={() => handleProfileClick(profile.id)}
            style={{ cursor: 'pointer' }}
          >
            <div className="d-flex align-items-stretch p-4" key={profile.id}>
              <div className='flex-grow-1'>
                <div className="me-3">
                  <h5 className="card-title">{profile.name}</h5>
                  <span className="card-text small">000{profile.id}</span>
                  <div className='d-lg-flex d-sm-block'>
                    <div className="card-text small mt-3 me-3">
                      <LuWallet className="me-2" /> {profile.salary}
                    </div>
                    <div className="card-text mt-3 small me-3">
                      <PiSuitcaseSimpleLight className="me-2" /> {profile.experience}
                    </div>

                  </div>
                  <div className='d-flex mt-3'>
                    <div className="card-text small">
                      <HiOutlineAcademicCap className="me-2" /> {profile.education}
                    </div>
                  </div>
                  <div className='d-flex mt-3'>
                    <div className="card-text small">
                      <IoSettingsOutline className="me-2" /> {profile.skills}
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <img src={profile.image} className="img-thumbnail rounded-circle border-0" alt={profile.name} style={{ maxWidth: '60px' }} />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default RelatedProfile; // Default export
