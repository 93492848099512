import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { PiSuitcaseSimpleLight } from "react-icons/pi";
import { CiLocationOn } from "react-icons/ci";
import { HiOutlineAcademicCap } from "react-icons/hi2";
import { IoSettingsOutline } from "react-icons/io5";
import { LuWallet } from "react-icons/lu";
import { GoLinkExternal } from "react-icons/go";

const JobCreationForm = () => {
    const [step, setStep] = useState(1);
    const [formData, setFormData] = useState({
        jobTitle: '',
        jobDescription: '',
        jobType: '',
        experienceLevel: '',
        country: '',
        city: '',
        salaryRange: '',
        companyName: '',
        companyTagline: '',
        companyWebsite: '',
        companySize: '',
        companyLogo: 'https://brandlogos.net/wp-content/uploads/2015/09/google-favicon-vector-400x400.png',
        twitterUsername: '',
        positionType: '',
        Department: '',
        workType: '',
        education: '',
        vacancies: '',
        postDate: '',
        location:'',
        expiryDate: ''
    });

    const nextStep = () => {
        setStep(step + 1);
    };

    const prevStep = () => {
        setStep(step - 1);
    };

    const handleChange = (input) => (e) => {
        setFormData({ ...formData, [input]: e.target.value });
    };

    const handleFileChange = (input) => (e) => {
        setFormData({ ...formData, [input]: e.target.files[0] });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Form submitted', formData);
        // Here you would handle form submission, such as sending data to an API
    };

    return (
        <div className="container">
        <div className="row mt-4">
            {/* Vertical Progress Bar with Dot Steps */}
            <div className="col-lg-3 pb-3">
                <ul className="progress-menu card p-4 rounded-4 list-group">
                    <li className={`progress-step ${step >= 1 ? 'active' : ''}`}>
                        <span className="progress-dot"></span>
                        <span className="progress-text card-text fw-normal">Job Details</span>
                    </li>
                    <li className={`progress-step ${step >= 2 ? 'active' : ''}`}>
                        <span className="progress-dot"></span>
                        <span className="progress-text card-text fw-normal">Company Details</span>
                    </li>
                    <li className={`progress-step ${step >= 3 ? 'active' : ''}`}>
                        <span className="progress-dot"></span>
                        <span className="progress-text card-text fw-normal">Review & Submit</span>
                    </li>
                </ul>
            </div>

            {/* Form Content */}
            <div className="col-lg-9 ps-lg-4">

                <form onSubmit={handleSubmit}>
                    {step === 1 && (
                        <div className="form-step fade-in pb-5">

                            <div className="d-flex flex-row align-items-center border-bottom mb-4 justify-content-between">
                                <div className='js-mn-title flex-grow-1 mt-3'>
                                    <h4 className='pb-2'>Job Details</h4>
                                </div>
                            </div>

                            <div className="mb-4">
                                <label className="form-label">Job Title</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={formData.jobTitle}
                                    onChange={handleChange('jobTitle')}
                                />
                            </div>
                            <div className="mb-4">
                                <label className="form-label">Job Description</label>
                                <textarea
                                    className="form-control"
                                    value={formData.jobDescription}
                                    onChange={handleChange('jobDescription')}
                                />
                            </div>
                            <div className='row'>
                                <div className="mb-4 col-md-6">
                                    <label className="form-label">Job Type</label>
                                    <select
                                        className="form-select"
                                        value={formData.jobType}
                                        onChange={handleChange('jobType')}
                                    >
                                        <option value="">Select Job Type</option>
                                        <option value="Full-time">Full-time</option>
                                        <option value="Part-time">Part-time</option>
                                        <option value="Contract">Contract</option>
                                        <option value="Temporary">Temporary</option>
                                        <option value="Internship">Internship</option>
                                    </select>
                                </div>
                                <div className="mb-4 col-md-6">
                                    <label className="form-label">Experience Level</label>
                                    <select
                                        className="form-select"
                                        value={formData.experienceLevel}
                                        onChange={handleChange('experienceLevel')}
                                    >
                                        <option value="">Select Experience Level</option>
                                        <option value="Entry">Entry</option>
                                        <option value="Mid">Mid</option>
                                        <option value="Senior">Senior</option>
                                        <option value="Manager">Manager</option>
                                        <option value="Director">Director</option>
                                    </select>
                                </div>
                            </div>

                            <div className='row'>
                                <div className="mb-4 col-md-6">
                                    <label className="form-label">Position Type</label>
                                    <select
                                        className="form-select"
                                        value={formData.positionType}
                                        onChange={handleChange('positionType')}
                                    >
                                        <option value="">Select Position Type</option>
                                        <option value="Permanent">Permanent</option>
                                        <option value="Temporary">Temporary</option>

                                    </select>
                                </div>
                                <div className="mb-4 col-md-6">
                                    <label className="form-label">Department</label>
                                    <select
                                        className="form-select"
                                        value={formData.Department}
                                        onChange={handleChange('Department')}
                                    >
                                        <option value="">Select Department Level</option>
                                        <option value="hr">Human Resources</option>
                                        <option value="engineering">Engineering</option>
                                        <option value="sales">Sales</option>
                                        <option value="marketing">Marketing</option>
                                    </select>
                                </div>
                            </div>


                            <div className='row'>
                                <div className="mb-4 col-md-6">
                                    <label className="form-label">Work Mode</label>
                                    <select
                                        className="form-select"
                                        value={formData.workType}
                                        onChange={handleChange('workType')}
                                    >
                                        <option value="">Select Work Mode</option>
                                        <option value="On-site">On-site</option>
                                        <option value="Remote">Remote</option>
                                        <option value="Hybrid">Hybrid</option>


                                    </select>
                                </div>
                                <div className="mb-4 col-md-6">
                                    <label className="form-label">Education</label>
                                    <select
                                        className="form-select"
                                        value={formData.education}
                                        onChange={handleChange('education')}
                                    >
                                        <option value="">Select Education</option>
                                        <option value="hr">Any Graduate</option>
                                        <option value="engineering">Any Post Graduate</option>
                                        <option value="sales">B.Tech/B.E. in Any Specialization, Any Graduate</option>
                                        <option value="marketing">MCA in Any Specialization</option>
                                    </select>
                                </div>
                            </div>

                            <div className='row'>
                                <div className="mb-4 col-md-6">
                                    <label for="location" class="form-label">Job Location</label>
                                    <input
                                        type="text"
                                        id="location"
                                        className="form-control"
                                        value={formData.location}
                                        onChange={handleChange('location')}
                                        placeholder="e.g., New York, California, Washington"
                                    />
                                </div>
                                <div className="mb-4 col-md-6">
                                    <label className="form-label">Salary Range</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={formData.salaryRange}
                                        onChange={handleChange('salaryRange')}
                                        placeholder="e.g., $60,000"
                                    />
                                </div>
                            </div>

                            <div class="mb-4">
                                <label for="location" class="form-label">Key Skills</label>
                                <input
type="text"
id="keySkills"
className="form-control"
value={formData.keySkills}
onChange={handleChange('keySkills')}
placeholder="e.g., Java, UX, HTML 5"
/>
                            </div>


                            <div className="mb-4">
                                <label className="form-label">No. of Vacancies</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={formData.vacancies}
                                    onChange={handleChange('vacancies')}
                                />
                            </div>
                            <div className='row'>
                                <div className="mb-4 col-md-4">
                                    <label for="datepicker" class="form-label">Post Date</label>
                                    <input
type="text"
id="postDate"
className="form-control"
value={formData.postDate}
onChange={handleChange('postDate')}
placeholder="Select date"
/>
                                </div>
                                <div className="mb-4 col-md-4">
                                    <label for="datepicker" class="form-label">Expiry Date</label>
                                    <input
type="text"
id="expiryDate"
className="form-control"
value={formData.expiryDate}
onChange={handleChange('expiryDate')}
placeholder="Select date"
/>
                                </div>

                                <div className="mb-4 col-md-4">
                                    <label className="form-label">Status</label>
                                    <select
                                        className="form-select"

                                    >
                                        <option value="active">Acitve</option>
                                        <option value="unactive">Unactive</option>
                                        <option value="drafts">Drafts</option>

                                    </select>
                                </div>
                            </div>
                            <button type="button" className="btn mt-3 px-5 py-3 btn-primary" onClick={nextStep}>
                                Next
                            </button>
                        </div>
                    )}
                    {step === 2 && (
                        <div className="form-step fade-in pb-5">
                            <div className="d-flex flex-row align-items-center border-bottom mb-4 justify-content-between">
                                <div className='js-mn-title flex-grow-1 mt-3'>
                                    <h4 className='pb-2'>Company Details</h4>
                                </div>
                            </div>
                            <div className="mb-4">
                                <label className="form-label">Company name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={formData.companyName}
                                    onChange={handleChange('companyName')}
                                />
                            </div>
                            <div className="mb-4">
                                <label className="form-label">Company Tagline</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={formData.companyTagline}
                                    onChange={handleChange('companyTagline')}
                                />
                            </div>
                            <div className="mb-4">
                                <label className="form-label">Company Website (optional)</label>
                                <input
                                    type="url"
                                    className="form-control"
                                    value={formData.companyWebsite}
                                    onChange={handleChange('companyWebsite')}
                                    placeholder="https://"
                                />
                            </div>
                            <div className="mb-4">
                                <label className="form-label">Company Size (optional)</label>
                                <input
                                    type="url"
                                    className="form-control"
                                    value={formData.companySize}
                                    onChange={handleChange('companySize')}
                                    placeholder="50"
                                />
                            </div>
                            <div className="mb-4">
                                <label className="form-label">Upload Company Logo (optional)</label>
                                <input
                                    type="file"
                                    className="form-control"
                                    onChange={handleFileChange('companyLogo')}
                                />
                            </div>
                            <div className="mb-4">
                                <label className="form-label">Twitter Username (optional)</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={formData.twitterUsername}
                                    onChange={handleChange('twitterUsername')}
                                    placeholder="@companyname"
                                />
                            </div>
                            <button type="button" className="btn mt-3 px-5 py-3 btn-outline-primary me-4" onClick={prevStep}>
                                Back
                            </button>
                            <button type="button" className="btn mt-3 px-5 py-3 btn-primary" onClick={nextStep}>
                                Next
                            </button>
                        </div>
                    )}
                    {step === 3 && (
                        <div className="form-step fade-in pb-5">
                            <div className="d-flex flex-row align-items-center border-bottom mb-4 justify-content-between">
                                <div className='js-mn-title flex-grow-1 mt-3'>
                                    <h4 className='pb-2'>Review & Submit</h4>
                                </div>
                            </div>


                            <div className='col-md-12'>

        <div className="card rounded-4 mb-4">
          <div className="d-flex align-items-stretch p-4">
            <div className="flex-grow-1">
              <h5 className="card-title">{formData.jobTitle}</h5>
              <span className="card-text">{formData.companyName}</span>
              <div className="d-flex mt-3">
                <div className="card-text me-3">
                  <LuWallet className="me-2" />$6,8440
                </div>
                <div className="card-text me-3">
                  <PiSuitcaseSimpleLight className="me-2" /> {formData.experienceLevel}
                </div>
                <div className="card-text">
                  <CiLocationOn className="me-2" />{formData.location}
                </div>
              </div>
              <div className="d-flex mt-3">
                <div className="card-text">
                  <HiOutlineAcademicCap className="me-2" />B.Sc. in Computer Science
                </div>
              </div>
              <div className="d-flex mt-3">
                <div className="card-text">
                  <IoSettingsOutline className="me-2" />  JavaScript, React, Node.js
                </div>
              </div>
            </div>
            <div>
              <img src={formData.image} className="img-thumbnail rounded-circle border-0" alt={formData.companyName} style={{ maxWidth: '100px' }} />
            </div>
          </div>
          <div className="d-flex justify-content-start border-top mx-4 mt-2">
            <div className="py-3 pe-5 border-end">
              <label className="small">Job Type</label>
              <p className="card-text pt-2">Full Time</p>
            </div>
            <div className="py-3 pe-5 ps-3 border-end">
              <label className="small">Work Mode</label>
              <p className="card-text pt-2">Work from office</p>
            </div>
            <div className="py-3 pe-5 ps-3 border-end">
              <label className="small">No. Openings</label>
              <p className="card-text pt-2">15</p>
            </div>

          </div>
        </div>

        <div className="card rounded-4 js-info-pg mb-0 p-4 pb-0">

          <h6 className='fs-5 mb-4'>Job Description</h6>

          <div className="mb-4" id="profile">

            <h6 className='fs-6 mb-1'>Summary</h6>
            <p className='card-text pt-2'>
              As an UI UX Architect at Gainwell, you can contribute your skills as we harness the power of technology to help our clients improve the health and well-being of the members they serve — a community’s most vulnerable. Connect your passion with purpose, teaming with people who thrive on finding innovative solutions to some of healthcare’s biggest challenges. Here are the details on this position.
            </p>


            <div className='js-skills mt-4'>
              <h6 className='fs-6 mb-1'>Job Responsibilities</h6>
              <div class="d-flex flex-wrap mb-3">
                <ul>
                  <li>Design and build advanced applications for the iOS platform using Swift.</li>
                  <li>Collaborate with cross-functional teams to define, design, and ship new features.</li>
                  <li>Ensure the performance, quality, and responsiveness of applications.</li>
                  <li>Identify and correct bottlenecks and fix bugs.</li>
                  <li>Help maintain code quality, organization, and automatization.</li>
                  <li>  Continuously discover, evaluate, and implement new technologies to maximize development efficiency.</li>
                </ul>
              </div>
            </div>



            <div className='js-skills mt-4'>
              <h6 className='fs-6 mb-1'>Preferred Qualifications</h6>
              <div class="d-flex flex-wrap mb-3">
                <ul>
                  <li>Experience with Flutter or React Native: Knowledge of cross-platform mobile development.</li>
                  <li>Published Apps: Experience with publishing applications on the Apple App Store.</li>
                  <li>Continuous Integration/Continuous Deployment: Familiarity with CI/CD pipelines.</li>
                  <li>Cloud Services: Experience with Firebase, AWS, or other cloud services.</li>
                  <li>Apple Developer Tools: Familiarity with Xcode, Instruments, and other Apple developer tools.</li>
                </ul>
              </div>
            </div>


            <div className='js-skills mt-4'>
              <h6 className='fs-6 mb-1'>Education</h6>
              <div class="d-flex flex-wrap mb-3">
              <p className='card-text pt-2'>UG: B.Sc in Any Specialization, BCA in Any Specialization, B.Tech/B.E. in Any Specialization</p>
              </div>
            </div>


            <div className='js-skills mt-4'>
              <h6 className='fs-6 mb-3'>Key Skills</h6>
              <div class="d-flex flex-wrap mb-3">
                <span class="p-2 mb-3 me-3 card-text px-3 small border bd-highlight">SQL Server</span>
                <span class="p-2 mb-3 me-3 card-text px-3 small border bd-highlight">Spring Integration</span>
                <span class="p-2 mb-3 me-3 card-text px-3 small border bd-highlight">Docker</span>
                <span class="p-2 mb-3 me-3 card-text px-3 small border bd-highlight">Restfui Web Services</span>
                <span class="p-2 mb-3 me-3 card-text px-3 small border bd-highlight">Servlets</span>
                <span class="p-2 mb-3 me-3 card-text px-3 small border bd-highlight">Spring Integration</span>
                <span class="p-2 mb-3 me-3 card-text px-3 small border bd-highlight">Spring Data JPA</span>
                <span class="p-2 mb-3 me-3 card-text px-3 small border bd-highlight">Spring Cloud</span>
                <span class="p-2 mb-3 me-3 card-text px-3 small border bd-highlight">PSQL Server</span>
                <span class="p-2 mb-3 me-3 card-text px-3 small border bd-highlight">JQuery</span>
                <span class="p-2 mb-3 me-3 card-text px-3 small border bd-highlight">SCSS</span>
                <span class="p-2 mb-3 me-3 card-text px-3 small border bd-highlight">HTML5</span>
              </div>
            </div>
          </div>
        </div>
        <div className='my-3 mb-5'>
        <button type="button" className="btn mt-3 px-5 py-3 btn-outline-primary me-4" onClick={prevStep}>
                                Back
                            </button>
                            <Link to='/jobposts' className="btn mt-3 px-5 py-3 btn-primary">
                            Submit
                            </Link>
                            </div>
      </div>


                        </div>
                    )}
                </form>
            </div>
        </div>
    </div>
    );
};
export default JobCreationForm;
