import React from 'react';
import { Link } from 'react-router-dom';

const ForgotPassword = () => {
  return (
    <div className="create-password-page js-pg-hg">
      <div className="container">
        <div className="row align-items-center justify-content-center">
        <div class="col-xl-4 col-lg-6 col-md-8 col-sm-8 col-11 mt-5 mt-xl-0">
        <div class="card shadow-sm d-flex mt-5 mt-xl-0 bx-center border-0 p-4">
              <div class="card-body">
                <form>
                  <h4>Reset Password</h4>
                  <label>Lost your password? Please Enter your email address. You will receive a link to create a new password via email</label>
                  <div className="mt-4">
                    <div className="form-group mb-4">
                      <label for="New Password" className="form-label">Email <span className='red'>*</span></label>
                      <input class="form-control"  type="email" placeholder="Enter email address" />
                    </div>
                  </div>
                  <Link to="/" className="btn btn-md px-5 py-3 w-100 mt-2 btn-primary" type="submit">Reset Password</Link>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
